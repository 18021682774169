<template>
  <!---审批流-->
  <div class="flow-body">
    <div v-for="(item, index) in list" :key="index" class="flow-item">
      <div class="flow-item-img"></div>
      <div class="flow-item-body">
        <div class="flow-item-line"/>
        <div class="flow-item-head">{{item.update_date}}</div>
        <div class="flow-item-info">
          <div class="flow-item-name">
            {{ item.check_user_id_info.realname || '' }}
             <span :class="getStatusClass(item.status)">{{ getStatusName(item.status) }}了此项目</span>
          </div>
        </div>
        <div v-if="item.content" class="flow-item-content">
          {{ item.content }}
          <div class="flow-item-content-arrow"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { crmExamineFlowRecordList } from "../../api/examine"; //审批记录
import { showDate } from "../../utils/tool";
export default {
  /** FormAuto management 的 Contract详情  查看审批流程*/
  name: "CheckFlow",
  components: {},
  props: {
    examineType: {
      type: String,
      default: ""
    },
    // 详情信息id
    id: [String, Number]
  },
  data() {
    return {
      loading: false,
      list: []
    };
  },
  computed: {},
  watch: {
    id: function(val) {
      this.list = [];
      this.getDetail();
    }
  },
  mounted() {
    this.getDetail();
  },
  methods: {
    getStatusClass: function(status) {
      // 0创建草稿，1待审核，2审核通过，3驳回修改，4已撤回, 5已作废
      if (status == 0) {
        return 'info'
      } else if (status == 1) {
        return 'info'
      } else if (status == 2) {
        return 'success'
      } else if (status == 3) {
        return 'warn'
      } else if (status == 4 || status == 5) {
        return 'fail'
      }
      return "";
    },
    showDate,
    getDetail() {
      this.loading = true;
      crmExamineFlowRecordList({
        types: this.examineType,
        types_id: this.id
      })
        .then(res => {
          this.loading = false;
          this.list = res.data;
          this.$emit("value-change", {
            config: res.data.config, // 审批流程
            value: [] // 审批信息
          });
        })
        .catch(() => {
          this.loading = false;
        });
    },
    // 获取State名称
    getStatusName(status) {
      // 0创建草稿，1待审核，2审核通过，3驳回修改，4已撤回, 5已作废
      if (status == 0) {
        return "创建草稿";
      } else if (status == 1) {
        return "待审核";
      } else if (status == 2) {
        return "审核通过";
      } else if (status == 3) {
        return "驳回修改";
      } else if (status == 4) {
        return "已撤回";
      } else if (status == 5) {
        return "已作废";
      }
      return "";
    },
    close() {
      this.$emit("close");
    }
  }
};
</script>

<style lang="less">
/** 每行的css */
.flow-item {
  position: relative;
  padding-bottom: 15px;
  .flow-item-img {
    width: 9px;
    height: 9px;
    background: #ffffff;
    border: 1px solid #2f4891;
    border-radius: 100%;
    float: left;
    position: absolute;
    z-index: 99;
  }
  .flow-item-body {
    min-height: 59px;
    background: #f6f8fc;
    border-radius: 10px;
    margin: 0 15px;
    padding: 7px 15px;
  }
  .flow-item-head {
    color: #999999;
    font-size: 13px;
    font-weight: 400;
    .flow-item-des {
      margin-right: 10px;
    }
  }
  .flow-item-info {
    margin-top: 8px;
    font-size: 12px;
    color: #979797;
    .flow-item-name {
      color: #333;
      font-weight: 600;
      font-size: 15px;
      margin-right: 10px;
    }
  }
  .flow-item-line {
    position: absolute;
    width: 1px;
    height: 100%;
    top: 0px;
    bottom: 0px;
    left: 4px;
    background-color: #e6e6e6;
  }
  .flow-item-content {
    position: relative;
    font-size: 12px;
    color: #929293;
    padding: 8px;
    line-height: 18px;
    .flow-item-content-arrow {
      width: 8px;
      height: 8px;
      background-color: #f7f8fa;
      transform: rotate(45deg);
      position: absolute;
      top: -4px;
      left: 25px;
    }
  }

  .info{
    color:#333;
  }
  .success{
    color:#2F4891;
  }
  .warn{
    color:#ff6034;
  }
  .fail{
    color:#929293
  }
}
</style>
