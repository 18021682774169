<template>
  <div class="page" :style="bgSty">
    <!--用户信息-->
    <Loading :show="show"/>
    <div
      class="detail_header"
      :style="'background: url('+ bg1 +') 0px 0px no-repeat;background-size: 100% 100%;'"
    >
      <div class="article-head">
        <div
          class="article-name"
          :style="'background: url('+ bg2 +') 0px 0px no-repeat;background-size: 100% 100%;'"
        >
          <p>{{detailInfo.name}}</p>
        </div>
        <div class="article-li">
          <div>
            <span class="li-title">总金额</span>
            <span class="li-value" style="color:#2F4891">{{detailInfo.price}}（CNY）</span>
          </div>
          <div>
            <span class="li-title">最终优惠金额</span>
            <span class="li-value" style="color:#2F4891">{{detailInfo.discount}}（CNY）</span>
          </div>
          <div>
            <span class="li-title">客户</span>
            <span class="li-value">{{detailInfo.customer_id_info ? detailInfo.customer_id_info.name : ''}}</span>
          </div>
          <div>
            <span class="li-title">联系人</span>
            <span class="li-value">{{detailInfo.contacts_id_info ? detailInfo.contacts_id_info.name : ''}}</span>
          </div>
          <div>
            <span class="li-title">销售</span>
            <span class="li-value">{{businessUser.length?businessUser[0].realname:''}}</span>
          </div>
          <div>
            <span class="li-title">报价人</span>
            <span class="li-value">{{detailInfo.create_user_id_info ? detailInfo.create_user_id_info.realname : ''}}</span>
          </div>
          <div>
            <span class="li-title">报价时间</span>
            <span class="li-value">{{showTime(detailInfo.update_time, 'YYYY/MM/DD')}}</span>
          </div>
        </div>
      </div>
    </div>
    <!--物料列表-->
    <div class="list_page">
      <material :id="detailInfo.product_id" :price="detailInfo.product_price"/>
    </div>
    <!--费用列表-->
    <div class="list_page">
      <fee :id="detailInfo.product_id" :price="detailInfo.fee_price"/>
    </div>
    <!--审批列表-->
    <div class="list_page">
      <div class="list_bg">
        <div class="header">
          <div style="width:70%;">
            <img src="../../assets/image/icon2@2x.png" style="width:19px;height:19px;">
            <span style="font-size: 18px;font-weight: 500;margin-left:8px;margin-top:-3px;">审批记录</span>
          </div>
        </div>
      </div>
      <div class="list_body" style="margin-bottom: 100px;">
        <check-flow
          ref="flows"
          :id="id"
          :examine-type="'crm_product'"
          @close="showFlowPopover=false"
        />
      </div>
    </div>
    <van-tabbar v-if="detailInfo.check_user_id == userInfo.id  && detailInfo.check_status == 1">
      <van-tabbar-item>
        <div style="margin-bottom: 10px;">
          <van-button class="red" type="warning" @click="examineHandle(3)">驳回修改</van-button>
        </div>
      </van-tabbar-item>
      <van-tabbar-item>
        <div style="margin-bottom: 10px;">
          <van-button class="blue" type="primary" @click="examineHandle(2)">审核通过</van-button>
        </div>
      </van-tabbar-item>
    </van-tabbar>
    <!-- 坦层 -->
    <examine
      :show="showExamineHandle"
      :id="id"
      :examine-user="businessUser"
      :flow_id="detailInfo.flow_id"
      :status="examineHandleInfo.status"
      @close="showExamineHandle = false"
      @save="examineHandleClick"
    />
  </div>
</template>

<script>
import Top from "./../../components/top";
import {
  crmProductRead,
  productItemIndex,
  crmBusinessUser
} from "./../../api/user";
import { showTime } from "./../../utils/tool";
import {
  Toast,
  Dialog,
  Button,
  Icon,
  Tabbar,
  TabbarItem
  // Loading
} from "vant";
import Loading from "../../components/loading";
import Store from "store";
import { mapState } from "vuex";
import material from "./components/material";
import fee from "./components/fee";
import CheckFlow from "./../../components/examine/CheckFlow";
import Examine from "./../../components/examine/index"; // 审批操作理由
import bg1 from "./../../assets/image/detail_box.png";
import bg2 from "./../../assets/image/article_bg.png";

export default {
  name: "detail",
  components: {
    Top,
    Button,
    Icon,
    Loading,
    material,
    fee,
    Examine,
    CheckFlow,
    Tabbar,
    TabbarItem
  },
  data() {
    return {
      bg1,
      bg2,
      bgSty: "",
      isIPhoneX: false,
      userInfo: {},
      id: 0,
      detailInfo: {
        name: "",
        price: 0,
        discount: 0,
        customer_id_info: { name: "" },
        contacts_id_info: { name: "" },
        create_user_id_info: { realname: "" },
        update_time: "",
        product_price: 0,
        fee_price: 0,
        check_user_id: 0,
        check_state: 0
      },
      examineHandleInfo: { status: 0 },
      showExamineHandle: false, // 审核操作
      businessUser: []
    };
  },
  created() {
    this.isIPhoneX = Store.get("isIPhoneX") || false;
    // console.log("手机尺寸大小是", screen);
    this.bgSty = "width:100%;bottom:100px;height:" + (screen.height + 150) + "px;";
    //this.getIsIPhoneX(); //是否iphoneX赋值.
    this.userInfo = Store.get("userInfo");

    this.id = this.$route.query.id;
    this.getDetail();
  },
  computed: {},
  methods: {
    showTime,
    /*
     **招聘列表数据接口
     **我的项目列表
     */
    async getDetail() {
      this.show = true;
      // console.log(this.id)
      let res = await crmProductRead({ id: this.id });
      if (res.code == 200) {
        this.detailInfo = res.data;
        // console.log(this.detailInfo)
        //获得审批流程
        this.getBusinessUser();
      } else {
        Toast.fail(res.error);
      }
      this.show = false;
    },
    //,
    // 审批操作点击// 撤回审批 通过 拒绝
    async examineHandle(status) {
      this.examineHandleInfo.status = status;
      this.showExamineHandle = true;
    },
    async examineHandleClick(data) {
      //console.log(data, '==data==')
      this.$refs.flows.getDetail();
      this.getDetail()
    },
    //获取销售员
    async getBusinessUser() {
      crmBusinessUser({ product_id: this.id })
        .then(res => {
          this.businessUser = res.data;
        })
        .catch(() => {});
    }
  }
};
</script>
<style lang="less">
.page {
  background-color: #f7f7f7;
  overflow-y: auto;
  /*用户信息*/
  .detail_header {
    padding: 60px 30px;
    width: 100%;
    height: 395px;

    .article-name {
      height: 80px;
      > p {
        padding: 20px 23px 0 23px;
        font-size: 20px;
        font-weight: 600;
        color: #000000;
      }
    }
    .article-li {
      padding: 10px 23px;
      > div {
        height: 30px;
      }
      .li-title {
        float: left;
        font-size: 15px;
        font-weight: 400;
        color: #999999;
        line-height: 21px;
        width: 40%;
      }
      .li-value {
        float: left;
        font-size: 15px;
        font-weight: 400;
        color: #333333;
        line-height: 21px;
        width: 60%;
      }
    }
  }

  .list_page {
    position: relative;
    color: #333333;
    margin-bottom: 10px;

    .header {
      width: 100%;
      display: flex;
      margin-bottom: 23px;
    }
    .list_bg {
      background: linear-gradient(180deg, #f1f5fc 0%, #ffffff 100%);
      border-radius: 20px 20px 0px 0px;
      position: relative;
      color: #333333;
      height: 50px;
      padding: 20px 22px;
    }
    .list_body {
      background-color: #fff;
      padding: 20px 22px;
      height: 100%;
    }
  }
  .van-tabbar {
    background: none !important;
    height: 60px;
  }
  .red {
    width: 170px;
    height: 45px;
    background: linear-gradient(145deg, #ffdee0 0%, #ffcace 100%);
    border-radius: 5px;
    color: #c6000d;
    font-size: 16px;
    font-weight: 400;
    border: none;
  }
  .blue {
    width: 170px;
    height: 45px;
    background: linear-gradient(105deg, #648ac2 0%, #46558c 100%);
    border-radius: 5px;
    color: #ffffff;
    font-size: 16px;
    font-weight: 400;
    border: none;
  }
}
</style>