<template>
  <van-dialog v-model:show="showDialog" title="审批意见" @confirm="handleClick('confirm')" @cancel="handleClick('cancel')" show-cancel-button confirm-button-color="#2f4891">
    <div style="padding: 20px 16px;">
      <van-field name="chooseType" label="审核结束" v-if="status == 2 && businessUser[0].id === userInfo.id">
        <template #input>
          <van-checkbox v-model="chooseType" shape="square"></van-checkbox>
        </template>
      </van-field>
      <van-field name="handleType" label="选择下一任审批人员" v-if="status == 2 && businessUser[0].id != userInfo.id">
        <template #input>
          <van-checkbox v-model="handleType" shape="square">{{businessUserName}}</van-checkbox>
        </template>
      </van-field>
      <van-field v-model="content" label="意见" rows="3" autosize type="textarea" placeholder="请输入您的意见"/>
      <div style="width:100%;color: #5E698B;font-size: 12px;font-weight: 400;margin-left:8px;">
        <van-icon name="info-o"/>
        <span>驳回时请必填审批意见</span>
      </div>
    </div>
  </van-dialog>
</template>
<script type="text/javascript">
import { crmProductCheck, crmProductRevokeCheck } from "../../api/examine";
import { Dialog, Button, Field, Toast } from "vant";
import Store from "store";

export default {
  name: "examine", // 审核操作
  components: {
    Button,
    Field,
    Dialog
  },
  props: {
    show: {
      type: Boolean,
      default: false
    },
    /** 操作类型  2通过3拒绝4撤回*/
    status: {
      type: [String, Number],
      default: 2
    },
    // 详情信息id
    id: [String, Number],
    // 审核信息 config 1 固定 0 自选
    flow_id: [String, Number],
    //
    examineUser: {
      type: Array,
      default: []
    }
  },
  data() {
    return {
      loading: false,
      showDialog: false,
      chooseType: 0,
      handleType: 0,
      selectUsers: [],
      content: "", // 输入内容
      //该项目销售人员
      businessUser: [],
      businessUserName: "",
      userInfo: {}
    };
  },
  created() {
    this.userInfo = Store.get("userInfo");
  },
  computed: {
    title() {
      if (this.status == 2) {
        return "审核通过";
      } else if (this.status == 3) {
        return "审核拒绝";
      } else if (this.status == 4) {
        return "撤回审核";
      }
      return "";
    },
    placeholder() {
      // 1通过0拒绝2撤回
      if (this.status == 2) {
        return "请输入审核意见（选填）";
      } else if (this.status == 3) {
        return "请输入审核意见（必填）";
      } else if (this.status == 4) {
        return "请输入撤回理由（必填）";
      }
      return "";
    }
  },
  watch: {
    show: {
      handler(val) {
        setTimeout(() => {
          this.businessUser = this.examineUser || []
          this.businessUserName = this.examineUser[0].realname || ''
          this.showDialog = val;
          //处理多选问题
          if(this.status == 2){
            if(this.examineUser.length && this.examineUser[0].id == this.userInfo.id){
              this.chooseType = 1
            } else {
              this.handleType = 1
            }
          }
        }, 500);
      },
      deep: true,
      immediate: true
    }
  },
  mounted() {},
  methods: {
    //如果方案审批//抓去下一任审批人员
    submitInfo() {
      if (this.status == 3 && !this.content) {
        Toast.fail('请输入您的意见')
        this.hiddenView();
      } else {
        if(this.status == 2 || this.status == 3) {
          // 1通过0拒绝2撤回
          this.handlePassAndReject();
        } else if (this.status == 2) {
          this.handleRevoke();
        }
      }
    },
    // 撤回操作
    handleRevoke() {
      this.loading = true;
      crmProductRevokeCheck({
        id: this.id,
        content: this.content
      })
        .then(res => {
          this.loading = false;
          Toast.success(res.data)
          this.$emit("save");
          this.hiddenView();
        })
        .catch(() => {
          this.loading = false;
        });
    },
    // 通过 拒绝操作
    handlePassAndReject() {
      this.loading = true;
      var params = {
        id: this.id,
        status: this.status,
        content: this.content,
        flow_id: this.flow_id
      };
      if (this.status == 2) {
        if (this.chooseType == 1) {
          params['is_end'] = 1
        } else {
          params['check_user_id'] = this.examineUser[0].id
        }
      }
      crmProductCheck(params)
        .then(res => {
          this.loading = false;
          Toast.success(res.data)
          this.$emit("save", { type: this.status });
          this.hiddenView();
        })
        .catch(() => {
          this.loading = false;
        });
    },
    handleClick(type) {
      if (type == "cancel") {
        this.hiddenView();
      } else if (type == "confirm") {
        this.submitInfo();
      }
    },
    hiddenView() {
      this.$emit("close");
    }
  }
};
</script>
<style lang="less">
.el-dialog__wrapper /deep/ .el-dialog__body {
  padding: 10px 25px 20px;
}

</style>
