<template>
<div>
    <Loading :show="show"/>
  <div class="list_bg">
    <div class="header">
      <div style="width:70%;">
        <img src="../../../assets/image/icon1@2x.png" style="width:19px;height:19px;">
        <span style="font-size: 18px;font-weight: 500;margin-left:8px;margin-top:-3px;">费用列表</span>
      </div>
    </div>
  </div>
  <div class="list_body">
    <!--用户导航-->
    <table style="width:100%;">
      <thead>
        <tr>
          <th style="width:30%">费用</th>
          <th style="width:15%;text-align: center;">类型</th>
          <th style="width:10%;text-align: center;">比例</th>
          <th style="width:15%;text-align: center;">小计</th>
        </tr>
      </thead>
      <tbody>
        <tr :key="i" v-for="(item, i) in itemList">
          <td style="width:30%">{{item.name}}</td>
          <td style="width:15%;text-align: center;">{{item.type == 'rate'?'比例':'固定'}}</td>
          <td style="width:10%;text-align: center;">{{item.rate}}</td>
          <td style="width:15%;text-align: center;">{{item.price}}</td>
        </tr>
      </tbody>
    </table>
    <div class="footer-li">
      <span style="color: #666666;font-size: 12px;">分项汇总二 (CNY)</span>
      <span style="color: #2F4891;font-size: 15px;">{{total_price}}</span>
    </div>
  </div>
</div>
</template>

<script>
import Top from "../../../components/top";
import { crmProductFeeIndex } from "../../../api/user";
import { showTime } from "../../../utils/tool";
import {
  Toast,
  Dialog,
  Button,
  Icon,
  // Loading
} from "vant";
import Loading from "../../../components/loading";
import { mapState } from "vuex";

export default {
  name: "fee",
  components: {
    Button,
    Icon,
    Loading
  },
  props: {
    id: [String, Number],
    price: [String, Number]
  },
  data() {
    return {
      show: false,
      itemList: [],
      total_price: 0
    };
  },
  created() {},
  computed: {},
  methods: {
    async getProductFee() {
      this.show = true
      var params = {
        product_id: this.id
      };
      let res = await crmProductFeeIndex(params);
      if (res.code == 200) {
        this.itemList = res.data.list;
        var total = 0
        for(var i = 0; i < res.data.list.length; i++){
          const item = res.data.list[i]
          // console.log('  ', item)
          total += parseFloat(item.price)
          // console.log('   ', total)
        }
        this.total_price = parseFloat(total).toFixed(2)
        this.show = false
      }
    }
  },
  watch: {
    id: {
      handler(val) {
        // console.log("项目id是   ", val);
        if (val) {
          this.getProductFee()
        }
      },
      deep: true,
      immediate: true
    }
  }
};
</script>

<style lang="less">
/*用户信息*/
.list_body{
  
  table {
    width: 100%;
    margin-top: 12px;
    color: #333;
    font-size: 14px;
    line-height: 1.5;
    border-collapse: collapse;
    table-layout: fixed;
  }

  table th {
    padding: 8px;
    text-align: left;
    background-color: #f0f2f4;
  }

  table th:first-child {
    padding-left: 0;
  }

  table th:last-child {
    padding-right: 0;
  }

  table td {
    word-wrap: break-word;
    padding: 8px 0;
    border-top: 1px solid #e9e9e9;
  }

  table td:last-child {
    padding-right: 0;
    color: #2f4891;
  }

  .footer-li {
    height: 40px;
    background: linear-gradient(180deg, #f7f7f7 0%, #fcfcfc 100%);
    padding: 11px 0;
    text-align: right;
    margin-top: 10px;
    margin-right: 7px;
  }
}
</style>