import Request from './request';

// Sales management下 ContractApplication信息
export function crmExamineFlowStepList(data) {
  return Request({
    url: 'admin/examine_flow/stepList',
    method: 'post',
    data: data
  })
}

/**
 * Application记录
 * @param {*} data
 * types
 * types_id
 */
export function crmExamineFlowRecordList(data) {
  return Request({
    url: 'admin/examine_flow/recordList',
    method: 'post',
    data: data
  })
}

/**
 * ContractApplication人信息
 * @param {*} data
 * types crm_contract
 */
export function crmExamineFlowUserList(data) {
  return Request({
    url: 'admin/examine_flow/userList',
    method: 'post',
    data: data
  })
}

/**
 * Contract审核
 * @param {*} data
 * id
 * status 1通过0拒绝
 * content
 */
export function crmProductCheck(data) {
  return Request({
    url: 'crm/product/check',
    method: 'post',
    data: data
  })
}

/**
 * Contract撤回Application
 * @param {*} data
 * id
 * status 1通过 0拒绝
 * 0失败，1通过，2撤回，3创建，4待审核 State信息
 */
export function crmProductRevokeCheck(data) {
  return Request({
    url: 'crm/product/revokeCheck',
    method: 'post',
    data: data
  })
}
